import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/www/src/layouts/ApiLayout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "about"
    }}>{`About`}</h1>
    <p className="lead">
	Get to know more about the team maintaining React Bootstrap. Learn a 
	little history of how, why and when the project started and how
	you can be a part of it.
    </p>
    <h3 {...{
      "id": "team"
    }}>{`Team`}</h3>
    <p>{`React Bootstrap is maintained by a `}<a parentName="p" {...{
        "href": "https://github.com/orgs/react-bootstrap/people"
      }}>{`team of developers`}</a>{` on Github. We have a growing team
and if you are interested in re-building the most popular front-end framework with React we would love to hear from you.`}</p>
    <h3 {...{
      "id": "contributors"
    }}>{`Contributors`}</h3>
    <p>{`We welcome community support with both feature and bug reporting. Please don't hesitate to jump in.
Join our growing list of `}<a parentName="p" {...{
        "href": "https://github.com/react-bootstrap/react-bootstrap/graphs/contributors"
      }}>{`contributors`}</a>{`.`}</p>
    <h3 {...{
      "id": "get-involved"
    }}>{`Get Involved`}</h3>
    <p>{`Get involved with React Bootstrap `}<a parentName="p" {...{
        "href": "https://github.com/react-bootstrap/react-bootstrap/issues/new"
      }}>{`by opening an issue`}</a>{` or submitting a pull request.
See our `}<a parentName="p" {...{
        "href": "https://github.com/react-bootstrap/react-bootstrap/blob/master/CONTRIBUTING.md"
      }}>{`contributing guidelines`}</a>{` here.`}</p>
    <h3 {...{
      "id": "external-links"
    }}>{`External Links`}</h3>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://getbootstrap.com/"
        }}>{`Bootstrap`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://reactjs.org/"
        }}>{`React`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://github.com/react-bootstrap/react-router-bootstrap"
        }}>{`React Router Bootstrap`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://github.com/Hermanya/awesome-react-bootstrap-components"
        }}>{`Awesome React Bootstrap Components`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://codesandbox.io/s/github/react-bootstrap/code-sandbox-examples/tree/master/basic"
        }}>{`React Bootstrap CodeSandbox examples`}</a></li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      